@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

.page {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.content {
  flex-grow: 1;
}

// Your variable overrides
$body-bg: white;
$body-color: #111;
$theme-colors: (
  "primary": rgb(200, 200, 4),
  "secondary": #444,
  "danger": #ff746c,
  "warning": rgb(150, 150, 100),
);


// Custom.scss
// Option A: Include all of Bootstrap

@import "node_modules/bootstrap/scss/bootstrap";

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value,
    // modify
    $hover-background: lighten($value, 17.5%),
    $hover-border: darker($value, 80%),
    $active-background: lighten($value, 10%),
    $active-border: lighten($value, 12.5%)
    // /modify
    );
  }
}

button {
  margin: 5px;
}

input[readonly] {
  background-color: lightgray;
  border: 0;
  font-size: 1em;
}
textarea[readonly] {
  background-color: lightgray;
  border: 0;
  font-size: 1em;
}
input[readonly]:focus {
  background-color: lightgray;
  border: 0;
  font-size: 1em;
}
textarea[readonly]:focus {
  background-color: lightgray;
  border: 0;
  font-size: 1em;
}

.bi {
  padding-right: 5px;
}

:root {
  --bs-link-color: rgb(113, 113, 2);
  --bs-link-hover-color: rgb(70, 70, 2);
}

.card-header {
  background: none;
  border: none;
}
